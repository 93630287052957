@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap");

$body-color: black;
$gs-color: rgb(49, 196, 245);
$block-margin: 4rem;

a {
    outline: none;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}

html {
    font-size: 16px;
}

body {
    color: $body-color;
    font-family: "Roboto", Arial, sans-serif;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $block-margin 0;

    #logo {
        display: block;
    }

    h1 {
        font-size: 2.5rem;
        text-align: center;
        color: $gs-color;
        margin: 1rem 0 3rem 0;
    }

    .subtitle {
        margin-bottom: 2rem;
    }

    .updated {
        font-weight: 300;
    }
}

.split-form {
    display: flex;
    width: 100%;

    & > div {
        width: 50%;
        flex: 1;
    }

    .form-wrapper {
        background-color: rgb(207, 223, 228);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-image {
        background-image: url("../img/background.jpg");
        background-position: center;
        background-size: cover;
    }
}

#form {
    padding: $block-margin 0;

    .row {
        &:not(:last-of-type) {
            margin-bottom: 1.5rem;
        }

        label {
            font-weight: 300;
            font-size: 0.9rem;
            margin-bottom: 0.25rem;
            display: block;
            cursor: pointer;

            &[for="agreement"] {
                flex: 1;
                display: block;
            }
        }

        &.inline {
            display: flex;
            align-items: center;
            justify-content: center;

            label {
                margin-bottom: 0;
            }
        }

        input {
            border: none;
            background-color: transparent;
            padding: 0.5rem 0 0.5rem 0;
            font-size: 1.25rem;
            outline: none;
            border-bottom: 1px solid black;
            border-top: 1px solid transparent;
            border-right: 1px solid transparent;
            border-left: 1px solid transparent;
            width: 100%;
            transition: 250ms border ease-out;

            &:focus {
                border: 1px solid black;
                outline: none;
            }

            &.submit {
                background-color: black;
                border: none;
                color: white;
                padding: 12px 15px;
                transition: 250ms background-color ease-out;
                cursor: pointer;
                margin-top: 1rem;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.75);
                }
            }

            &[type="checkbox"] {
                cursor: pointer;
                transform: scale(1.5);
            }
        }

        .agreement-input-wrapper {
            width: 50px;
        }
    }
}

.video-wrapper {
    width: 100%;
    position: relative;

    video {
        object-fit: cover; // this is the key
        width: 100%;
        height: 680px;

        &::-webkit-media-controls {
            display: none !important;
        }
    }
}

#footer {
    align-self: end;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .socials {
        display: flex;
        margin: $block-margin 0;
    }

    a {
        display: block;

        &:not(:last-of-type) {
            margin-right: 1rem;
        }
    }

    img {
        width: 30px;
        height: 30px;
    }

    .copyright {
        text-align: center;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 960px) {
    .split-form {
        flex-direction: column;

        & > div {
            width: 100%;
            flex: 1;
        }

        .form-image {
            display: none;
        }
    }
}
